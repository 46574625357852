





















































































































// Vue
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
// Models
import { IQuantity } from "@/core/_models/quantity.model";
// Services
import AdminService from "@/views/admin/Admin.service";
// Components
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";
import CreateQuantityDialog from "@/views/admin/_dialogs/quantities/CreateQuantityDialog.vue";
import UpdateQuantityDialog from "@/views/admin/_dialogs/quantities/UpdateQuantityDialog.vue";

export default Vue.extend({
  name: "Quantities",
  components: { draggable, CreateQuantityDialog, ConfirmationDialog, UpdateQuantityDialog },
  data() {
    return {
      isLoading: false,
      isSearching: false,

      search: "",

      quantitiesData: [] as IQuantity[]
    };
  },

  beforeMount() {
    this.getAllQuantities();
  },

  // Update the indexes of all Quantities on Destroy
  beforeDestroy() {
    this.saveLayout();
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    saveLayout(): void {
      this.isLoading = true;

      let index = 0;
      this.quantitiesData.forEach((element: IQuantity) => {
        element.Index = index;
        index++;

        AdminService.updateQuantity(this.currentUser.clientId, element).then(() => {
          this.isLoading = false;
        });
      });
    },

    getAllQuantities(): void {
      this.isLoading = true;

      AdminService.getAllQuantities(this.currentUser.clientId).then((data: IQuantity[]) => {
        if (!data) return;
        this.quantitiesData = data;
        this.isLoading = false;
      });
    },

    resetSearch(): void {
      this.search = "";
      this.isSearching = false;
    },

    deleteQuantity(elementToDelete: IQuantity): void {
      AdminService.deleteQuantity(this.currentUser.clientId, elementToDelete.DatabaseId!)
        .then(() => {
          this.getAllQuantities();
          this.resetSearch();
          this.ADD_SUCCESS_MESSAGE("Quantity successfully deleted.");
        })
        .catch((error) => {
          console.log(error);
          this.ADD_SUCCESS_MESSAGE("Error deleting quantity. Please try again.");
        });
    }
  },

  computed: {
    ...mapGetters({
      items: "quantities/items",
      currentUser: "currentUser"
    }),

    /**
     * Filters Quantities via search bar
     * @author Nick Brahimir
     */
    filteredQuantities(): any {
      return this.quantitiesData.filter((element: IQuantity): any => {
        return element.Name?.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    /**
     * Animations for Drag n Drop (see docs below)
     * https://www.npmjs.com/package/vuedraggable
     * https://sortablejs.github.io/Vue.Draggable/#/transition-example-2
     * https://github.com/SortableJS/Vue.Draggable/blob/master/example/components/transition-example-2.vue
     * @author Nick Brahimir
     */
    dragOptions(): any {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost" // Corresponds to the scoped scss class.
      };
    },

    dragOff(): any {
      return {
        disabled: true
      };
    }
  }
});
