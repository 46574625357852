





























































// Vue
import Vue, { PropType } from "vue";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Models
import { IQuantity } from "@/core/_models/quantity.model";
import { mapActions, mapGetters } from "vuex";
// Services
import AdminService from "@/views/admin/Admin.service";

export default Vue.extend({
  name: "CreateQuantityDialog",
  props: {
    quantity: Object as PropType<IQuantity>
  },

  data() {
    return {
      dialog: false,

      name: undefined as string | undefined,
      index: undefined as number | undefined
    };
  },

  mixins: [validationMixin],

  validations: {
    name: { required }
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    init(): void {
      if (this.quantity) {
        this.name = this.quantity.Name;
        this.index = this.quantity.Index;
      }
    },

    submit(): void {
      if (!this.name) return;

      const payload: IQuantity = {
        DatabaseId: this.quantity.DatabaseId,
        Name: this.name,
        Index: this.index!
      };

      // * Submit to server
      AdminService.updateQuantity(this.currentUser.clientId, payload)
        .then(() => {
          this.$emit("refresh-quantities");
          this.close();
          this.ADD_SUCCESS_MESSAGE("Quantity successfully updated.");
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE("Error updating quantity. Please try again.");
        });

      this.close();
    },

    close(): void {
      this.name = undefined;

      this.$v.$reset();
      this.dialog = false;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    nameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    }
  }
});
